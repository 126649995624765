<template>
  <footer class="base-footer">
    <div class="base-footer__container">
      <div>
        <a href="https://weclever.co/" target="_blank">
          <img
            :src="require('@/assets/images/weclever-logo.png')"
            alt="WeClever Logo"
            class="base-footer__weclever-logo"
          >
        </a>
      </div>
      <div>
        <a href="https://www.instagram.com/wecleverco" target="_blank">
          <img
            :src="require('@/assets/images/icon-instagram.png')"
            alt="WeClever Logo"
            class="base-footer__social-icon"
          >
        </a>
        <a href="https://www.linkedin.com/company/wecleverco/" target="_blank">
          <img
            :src="require('@/assets/images/icon-linkedin.png')"
            alt="WeClever Logo"
            class="base-footer__social-icon"
          >
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BaseFooter'
}
</script>

<style lang="scss" scoped>
$wec-dark-blue: nth($colors-custom, 1);

.base-footer {
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: $wec-dark-blue;
  height: 187px;

  &__container {
    display: grid;
    justify-items: center;
    background: $wec-dark-blue;
  }

  &__weclever-logo {
    align-self: center;
    justify-self: center;
    width: 101px;
    height: 41px;
    margin-bottom: 15px;
  }

  &__social-icon {
    width: 38px;
    height: 36px;
    margin: 3px;
  }
}
</style>
