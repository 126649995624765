<template>
  <div class="home-view">
    <BaseHeader/>

    <main class="home-view__main">
      <EbookDescription/>
      <EbookLeadForm/>
    </main>

    <BaseFooter/>
  </div>
</template>

<script>
export default {
  name: 'HomeView'
}
</script>

<style lang="scss" scoped>
  .home-view {}
</style>
