<template>
  <div class="ebook-lead-form">
    <div id="cta" class="ebook-lead-form__text-div">
      <div v-if="!isMobile" class="ebook-lead-form__cta-div-1">
        <svg width="50" height="271" viewBox="0 0 50 271" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5598 45.6971L14.9565 45.1974L14.3765 44.9316C6.15911 41.1653 0.5 33.1578 0.5 23.915C0.5 11.0612 11.468 0.500031 25.0152 0.500031C38.5637 0.500031 49.5 10.9751 49.5 23.8568C49.5 36.0844 39.6376 46.1285 27.0476 47.1279L26.8575 47.143L26.7258 47.2808C18.3496 56.0405 14.2855 64.9161 14.6703 73.8431C15.055 82.7649 19.8787 91.6212 29.027 100.357C39.299 110.165 45.1016 120.508 46.3037 131.064C46.4546 132.475 46.5445 133.853 46.5445 135.227C46.5445 147.063 40.6951 158.666 29.027 169.807C19.8174 178.601 14.9928 187.517 14.6626 196.495C14.3322 205.477 18.5046 214.403 27.0327 223.205L27.1621 223.339L27.3473 223.355C39.7851 224.469 49.4695 234.483 49.4695 246.598C49.4695 259.48 38.5332 269.955 24.9848 269.955C11.4363 269.955 0.5 259.48 0.5 246.598C0.5 237.27 6.2482 229.234 14.5562 225.495L15.1446 225.231L14.7413 224.727C6.59307 214.55 3.11189 204.066 4.24099 193.661C5.37093 183.248 11.1268 172.833 21.6128 162.821C32.2152 152.697 37.0848 142.398 35.9019 132.173C34.9394 123.842 30.1028 115.508 21.6131 107.402C11.1723 97.4177 5.416 87.0381 4.25222 76.6606C3.08934 66.2912 6.50295 55.8438 14.5598 45.6971Z" fill="#CBFF00" stroke="#172542"/>
        </svg>
      </div>

      <div class="ebook-lead-form__cta-div-2">
        <p class="ebook-lead-form__cta-message">
          <span class="ebook-lead-form__cta-message-bold">
            Confira o material completo
          </span>
          e transforme seus atendimentos em momentos "Uau"!
        </p>
      </div>

      <div v-if="!isMobile" class="ebook-lead-form__cta-div-3">
        <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.1952 26.2578L23.195 26.2578L23.1987 26.2713C24.1925 29.8962 27.0306 32.5367 30.5588 33.5079C32.7409 34.133 34.861 34.9411 36.9194 35.9322C37.9024 36.4055 37.9115 37.6979 36.9685 38.1732C34.9632 39.1839 32.8346 39.9551 30.5814 40.4864C26.8826 41.3255 24.1897 44.1205 23.2001 47.7235C22.5563 49.8902 21.722 51.9949 20.6968 54.0377C20.217 54.9939 18.9511 55.0046 18.4683 54.0877C17.4199 52.0969 16.6219 49.9821 16.0745 47.7422L16.0747 47.7422L16.071 47.7287C15.077 44.1029 12.2377 41.4619 8.70824 40.4914L8.70347 40.4901C6.36298 39.8713 4.20644 39.0736 2.15019 38.0969C1.15792 37.6255 1.15418 36.3138 2.12228 35.8436C4.22089 34.8242 6.42426 34.0473 8.69037 33.5132L8.69041 33.5133L8.70347 33.5099C12.3852 32.5364 15.0774 29.7529 16.0689 26.2789C16.7128 24.1113 17.5474 22.0059 18.5729 19.9623C19.0527 19.0061 20.3186 18.9954 20.8015 19.9123C21.8498 21.9031 22.6478 24.0179 23.1952 26.2578Z" fill="#F7C689" stroke="#172542" stroke-linejoin="round"/>
          <path d="M54.5 6C54.5 9.03757 52.0376 11.5 49 11.5C45.9624 11.5 43.5 9.03757 43.5 6C43.5 2.96243 45.9624 0.5 49 0.5C52.0376 0.5 54.5 2.96243 54.5 6Z" fill="#F7C689" stroke="black"/>
        </svg>
      </div>
    </div>

    <div id="form" class="ebook-lead-form__form-div">
      <CleverCard class="ebook-lead-form__clever-card">
        <div v-if="!downloadedEbook" class="ebook-lead-form__content">
          <p class="ebook-lead-form__title">
            Baixe o Ebook Gratuitamente
          </p>
          <p class="ebook-lead-form__subtitle">
            Preencha seus dados abaixo para ter acesso ao material
          </p>

          <label for="name" class="ebook-lead-form__label">
            Nome <span class="ebook-lead-form__asterisk">*</span>
          </label>
          <input
            v-model="leadForm.name"
            type="text"
            id="name"
            name="name"
            class="ebook-lead-form__input"
            :class="clickedDownloadBtn && !leadForm.name ? 'ebook-lead-form__invalid-field' : ''"
            required
          >

          <label for="email" class="ebook-lead-form__label">
            E-mail <span class="ebook-lead-form__asterisk">*</span>
          </label>
          <input
            v-model="leadForm.email"
            type="text"
            id="email"
            name="email"
            class="ebook-lead-form__input"
            :class="clickedDownloadBtn && !isValidEmail(leadForm.email) ? 'ebook-lead-form__invalid-field' : ''"
            required
          >

          <label for="email-confirmation" class="ebook-lead-form__label">
            Telefone <span class="ebook-lead-form__asterisk">*</span>
          </label>
          <input
            v-model="leadForm.phone"
            type="text" id="phone"
            name="phone" class="ebook-lead-form__input"
            :class="clickedDownloadBtn && !isValidPhone(leadForm.phone) ? 'ebook-lead-form__invalid-field' : ''"
            required
            v-mask="phoneMask"
            @blur="phoneBlur"
            @focus="phoneFocus"
          >

          <label for="profession" class="ebook-lead-form__label">
            Profissão/Função <span class="ebook-lead-form__asterisk">*</span>
          </label>
          <input
            v-model="leadForm.profession"
            type="text"
            id="profession"
            name="profession"
            class="ebook-lead-form__input"
            :class="clickedDownloadBtn && !leadForm.profession ? 'ebook-lead-form__invalid-field' : ''"
            required
          >

          <label for="company" class="ebook-lead-form__label">
            Empresa <span class="ebook-lead-form__asterisk">*</span>
          </label>
          <input
            v-model="leadForm.company"
            type="text"
            id="company"
            name="company"
            class="ebook-lead-form__input"
            :class="clickedDownloadBtn && !leadForm.company ?
            'ebook-lead-form__invalid-field' : ''"
            required
          >

          <label for="n-employees" class="ebook-lead-form__label">
            Nº de funcionários <span class="ebook-lead-form__asterisk">*</span>
          </label>
          <select
            v-model="leadForm.numEmployees"
            name="n-employees"
            id="n-employees"
            class="ebook-lead-form__input ebook-lead-form__select"
            :class="clickedDownloadBtn && !leadForm.numEmployees ? 'ebook-lead-form__invalid-field' : ''"
            required
          >
            <option value="" disabled selected>Selecione uma opção</option>
            <option value="1-10">1 - 10</option>
            <option value="11-50">11 - 50</option>
            <option value="51-250">51 - 250</option>
            <option value="251-500">251 - 500</option>
            <option value="500+">500+</option>
          </select>

          <div class="ebook-lead-form__radio-div">
            <span class="ebook-lead-form__radio-question">Já sou cliente da WeClever</span>

            <br v-if="isMobile">

            <label for="yes" class="ebook-lead-form__radio-option">
              Sim
              <input v-model="leadForm.wecleverCustomer" type="radio" id="already-customer" name="already-customer" value="true" class="ebook-lead-form__radio-circle" required>
            </label>

            <label for="no" class="ebook-lead-form__radio-option" :class="isMobile ? 'ebook-lead-form__radio-option-margin' : ''">
              Não
              <input v-model="leadForm.wecleverCustomer" type="radio" id="already-customer" name="already-customer" value="false" class="ebook-lead-form__radio-circle" required>
            </label>
          </div>

          <div class="ebook-lead-form__checkbox-div">
            <input
              v-model="leadForm.acceptedPrivacyPolicy"
              type="checkbox"
              id="agreement"
              name="agreement"
              value="true"
              class="ebook-lead-form__checkbox"
            >
            <label for="agreement">
              Li e aceito os
              <a
                id="privacy-policy"
                target="_blank"
                href="https://weclever.co/files/weclever-aviso-externo-de-privacidade.pdf"
              >
                termos da política de privacidade
              </a>
            </label>
          </div>

          <a
            v-if="isValidForm(leadForm)"
            id="validDownloadAnchor"
            href="https://cleversale.s3.amazonaws.com/ebooks/Atendimento+Humanizado+%5BWeClever%5D.pdf"
            target="_blank"
            @click="downloadEbook()"
            download
            class="ebook-lead-form__anchor-button"
          >
            <button id="validDownloadBtn" class="ebook-lead-form__button">
              Baixar Ebook
            </button>
          </a>
          <button
            v-else
            id="invalidDownloadBtn"
            @click="() => { clickedDownloadBtn = true }"
            class="ebook-lead-form__button"
          >
            Baixar Ebook
          </button>
        </div>

        <div v-else class="ebook-lead-form__thank-content">
          <p class="ebook-lead-form__thank-title">
            Agradecemos por baixar nosso ebook
          </p>
          <p class="ebook-lead-form__thank-text">
            Esperamos que sua experiência seja "Uau".
          </p>
          <p class="ebook-lead-form__thank-text">
            💚
          </p>
          <img
            src="@/assets/images/thank-you.svg"
            class="ebook-lead-form__thank-img"
          >
        </div>
      </CleverCard>
    </div>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'EbookLeadForm',

  data () {
    return {
      isMobile: window.innerWidth <= 1280,
      isFocusedPhone: false,
      clickedDownloadBtn: false,
      isValidDownload: false,
      downloadedEbook: false,

      leadForm: {
        name: '',
        email: '',
        phone: '',
        profession: '',
        company: '',
        numEmployees: '',
        wecleverCustomer: null,
        acceptedPrivacyPolicy: false
      },
      phoneMask: '(##) ?#########'
    }
  },

  methods: {
    phoneFocus () {
      this.isFocusedPhone = true
      this.phoneMask = '(##) ?#########'
    },

    phoneBlur () {
      this.isFocusedPhone = false
      const { length: phoneLength } = this.leadForm.phone
      let phoneMask = '(##) ?#########'

      switch (true) {
        case (phoneLength === 13):
          phoneMask = '(##) #### ####'
          break

        case (phoneLength === 14):
          phoneMask = '(##) ##### ####'
      }

      this.phoneMask = phoneMask
    },

    isValidPhone (phone) {
      if (
        phone &&
        (
          (this.isFocusedPhone && phone.length >= 13) ||
          (!this.isFocusedPhone && phone.length >= 14)
        )
      ) return true
      return false
    },

    parseBoolean (str) {
      if (str === 'false' || str === false) return false
      if (str === 'true' || str === true) return true
      return null
    },

    isValidEmail (email) {
      if (
        email &&
        email.indexOf('@') > 0 &&
        email.indexOf(' ') < 0
      ) return true
      return false
    },

    isValidForm (form) {
      form.wecleverCustomer = this.parseBoolean(form.wecleverCustomer)

      if (
        form.name &&
        this.isValidEmail(form.email) &&
        this.isValidPhone(form.phone) &&
        form.profession &&
        form.company &&
        form.numEmployees &&
        [true, false].includes(form.wecleverCustomer) &&
        form.acceptedPrivacyPolicy
      ) return true
      return false
    },

    sanitizeForm () {
      const options = {
        allowedTags: [],
        allowedAttributes: {}
      }

      for (const field in this.leadForm) {
        const ignoredFields = [
          'wecleverCustomer',
          'acceptedPrivacyPolicy'
        ]

        if (!ignoredFields.includes(field)) {
          this.leadForm[field] = sanitizeHtml(this.leadForm[field], options)
        }
      }
    },

    downloadEbook () {
      this.sanitizeForm()

      const jsonBody = {
        id_platform: 685,
        action: 'LP-ebook',
        plugin: 'checkout_lp_identificacao',
        phone: this.leadForm.phone,
        name: this.leadForm.name,
        email: this.leadForm.email,
        occupation: this.leadForm.profession,
        company_nickname: this.leadForm.company,
        employees_amount: this.leadForm.numEmployees,
        already_customer: this.leadForm.wecleverCustomer
      }
      const xhr = new XMLHttpRequest()
      xhr.open('POST', 'https://api.weclever.co/lead', !0)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(JSON.stringify(jsonBody))

      this.clickedDownloadBtn = true
      this.downloadedEbook = true
    }
  }
}
</script>

<style lang="scss" scoped>
$wec-dark-blue: nth($colors-custom, 1);
$wec-green: nth($colors-custom, 2);
$wec-light-blue: nth($colors-custom, 3);
$gray: #838b9d;
$red: #ff3844;

$mobile-max-width-breakpoint: map-get($breakpoints, 'lg');
$desktop-min-width-breakpoint: map-get($breakpoints, 'lg') + 1px;

@mixin desktop-spacing {
  margin-left: 5vw;
  max-width: 95vw;
}

@mixin mobile-spacing {
  margin-left: 10vw;
  margin-right: 10vw;
}

// @media: mobile
@media only screen and (max-width: $mobile-max-width-breakpoint) {
  #cta { grid-area: cta; }
  #form { grid-area: form; }

  .ebook-lead-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "cta"
      "form";

    overflow: hidden;
    padding-top: 45px;
    padding-bottom: 45px;
    background-color: $wec-light-blue;

    &__text-div {
      align-content: center;
    }

    &__cta-div-1 {
      align-self: center;
      justify-self: end;
      margin-right: 10px;
    }

    &__cta-div-2 {
      align-self: center;
      justify-self: end;
    }

    &__cta-div-3 {
      align-self: end;
      justify-self: start;
    }

    &__cta-message {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 290px;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: $wec-dark-blue;

      &-bold {
        font-weight: 700;
      }
    }

    &__form-div {
      padding-left: 9vw;
      padding-right: 8vw;
    }

    &__clever-card {
      margin-top: 45px;
      padding-top: 35px;
      padding-bottom: 32px;
    }

    &__content {
      transform: translate(-6px, -6px);
    }

    &__title {
      max-width: 310px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      text-transform: uppercase;
      font-size: 17px;
      font-weight: 900;
      line-height: 27px;
    }

    &__subtitle {
      max-width: 255px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    &__label {
      display: block;
      margin-left: 8vw;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      color: $gray;
    }

    &__input {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      height: 44px;
      width: 73vw;
      padding: 10px 20px;
      gap: 8px;
      margin-left: 5vw;
      margin-right: 5vw;
      margin-bottom: 10px;

      border: 1px solid $wec-dark-blue;
      border-radius: 10px;

      &[type='text'] {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: $wec-dark-blue;
      }
    }

    &__radio-circle {
      &[type='radio'] {
        width: 20px;
        height: 20px;
        border: 1px solid $wec-dark-blue;
        transform: translate(0, 4px);

        &:checked {
          border: 1px solid $wec-green;
          width: 20px;
          height: 20px;
        }
      }
    }

    &__select {
      appearance: none !important;
      height: 44px;
      background: {
        color: transparent;
        image: url("@/assets/images/select-arrow.png");
        repeat: no-repeat;
        position: calc(100% - 20px) 50%;
        size: 18px auto;
      }
      padding-right: 60px;
    }

    &__select > option {
      background-color: #fff;
      padding: 10px 20px;
      gap: 8px;

      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #172542;
    }

    &__asterisk {
      color: $red;
    }

    &__invalid-field {
      background-color: #f4dee0
    }

    &__radio-div {
      display: block;
      justify-content: space-around;
      margin-top: 26px;
      margin-left: 6vw;
      accent-color: $wec-green;
    }

    &__radio-question {
      font-size: 12px;
      line-height:15px;
      color: $wec-dark-blue;
    }

    &__radio-option {
      font-size: 12px;
      line-height: 15px;
      appearance: none;
      outline: 0;
    }

    &__radio-option-margin {
      margin-left: 6vw;
    }

    &__checkbox-div {
      display: block;
      justify-content: space-around;
      margin-top: 25px;
      margin-left: 6vw;
      margin-right: 6vw;
      accent-color: $wec-green;

      font-size: 12px;
      line-height: 20px;
    }

    &__checkbox {
      transform: translate(0, 2px);
      width: 15px;
      height: 15px;
    }

    &__button {
      display: flex;
      margin: auto;
      width: 70%;
      max-width: 255px;
      height: 38px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 30px;
      gap: 10px;
      margin-top: 26px;
      background: $wec-light-blue;
      border-radius: 100px;
      border: 0;

      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      color: $wec-dark-blue;
    }

    &__thank-content {
      max-width: 180px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 160px;
      margin-bottom: 230px;
      text-align: center;
    }

    &__thank-title {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      text-align: center;

      text-align: center;
      font-size: 14px;
      font-weight: 900;
      line-height: 25px;
    }

    &__thank-text {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      text-align: center;

      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
    }

    &__thank-img {
      margin-top: 30px;
      max-width: 180px;
    }
  }
}

// @media: desktop
@media only screen and (min-width: $desktop-min-width-breakpoint) {
  .ebook-lead-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: $wec-light-blue;

    &__text-div {
      padding-left: 10vw;
      width: 50vw;
      align-content: center;

      display: grid;
      grid-template-columns: 0.15fr 1fr 0.1fr;
    }

    &__cta-div-1 {
      align-self: center;
      justify-self: end;
      margin-right: 10px;
    }

    &__cta-div-2 {
      align-self: center;
      justify-self: end;
      margin-left: 20px;
    }

    &__cta-div-3 {
      align-self: end;
      justify-self: start;
    }

    &__cta-message {
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
      color: $wec-dark-blue;

      &-bold {
        font-weight: 700;
      }
    }

    &__form-div {
      padding-left: 5vw;
      width: 50vw;
    }

    &__clever-card {
      max-width: 480px;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__content {
      transform: translate(-6px, -6px);
    }

    &__title {
      max-width: 225px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      text-transform: uppercase;
      font-size: 25px;
      font-weight: 900;
      line-height: 40px;
    }

    &__subtitle {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }

    &__label {
      display: block;
      margin-left: 50px;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      color: $gray;
    }

    &__input {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      height: 44px;
      width: 100%;
      max-width: 400px;
      padding: 10px 20px;
      gap: 8px;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 10px;

      border: 1px solid $wec-dark-blue;
      border-radius: 10px;

      &[type='text'] {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: $wec-dark-blue;
      }
    }

    &__radio-circle {
      &[type='radio'] {
        width: 20px;
        height: 20px;
        border: 1px solid $wec-dark-blue;
        transform: translate(0, 4px);

        &:checked {
          border: 1px solid $wec-green;
          width: 20px;
          height: 20px;
        }
      }
    }

    &__select {
      appearance: none;
      height: 44px;
      background: {
        color: transparent;
        image: url("@/assets/images/select-arrow.png");
        repeat: no-repeat;
        position: calc(100% - 20px) 50%;
        size: 18px auto;
      }
      padding-right: 60px;
    }

    &__select > option {
      background-color: #fff;
      padding: 10px 20px;
      gap: 8px;

      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #172542;
    }

    &__asterisk {
      color: $red;
    }

    &__invalid-field {
      background-color: #f4dee0
    }

    &__radio-div {
      display: block;
      justify-content: space-around;
      margin-top: 28px;
      margin-left: 40px;
      accent-color: $wec-green;
    }

    &__radio-option {
      margin-left: 20px;
    }

    &__checkbox-div {
      display: block;
      justify-content: space-around;
      margin-top: 28px;
      margin-left: 40px;
      accent-color: $wec-green;
    }

    &__checkbox {
      transform: translate(0, 5px);
      width: 20px;
      height: 20px;
    }

    &__button {
      display: flex;
      margin: auto;
      width: 338px;
      height: 38px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 30px;
      gap: 10px;
      margin-top: 28px;
      background: $wec-light-blue;
      border-radius: 100px;
      border: 0;

      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $wec-dark-blue;
    }

    &__thank-content {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 120px;
      margin-bottom: 120px;
      text-align: center;
    }

    &__thank-title {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
      text-align: center;

      text-align: center;
      font-size: 24px;
      font-weight: 900;
      line-height: 32px;
    }

    &__thank-text {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      text-align: center;

      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }

    &__thank-img {
      margin-top: 50px;
    }
  }
}

#privacy-policy {
  display: inline;
  cursor: pointer;
  color: #38c2ca;
}
</style>
