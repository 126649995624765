<template>
  <div class="ebook-description">

    <div id="title">
      <p class="ebook-description__upper-title">Ebook</p>
      <p class="ebook-description__title">
        Atendimento Humanizado:
      </p>
      <p class="ebook-description__title">
        Como e por que conversas ajudam a vender mais?
      </p>
      <div class="ebook-description__subtitle-div">
        <p>
          <span class="ebook-description__subtitle">
            Transforme seus atendimentos e crie conexão com seus clientes!
          </span>
        </p>
      </div>
    </div>

    <div id="text">
      <p class="ebook-description__text">
        Prepare-se para descobrir como focar no cliente, gerar insights valiosos
        e acelerar suas vendas!
      </p>
      <p class="ebook-description__text">
        Diariamente conversamos com centenas de pessoas que estão realizando
        compras online. Atendendo a clientes de grandes marcas em diversos
        setores do mercado, aprendemos muito e queremos compartilhar um
        pouquinho deste conhecimento com você.
      </p>
      <div class="ebook-description__clever-card">
        <CleverCard >
          <p class="ebook-description__card-text">
            Reunimos neste ebook conceitos importantes sobre a nova economia, o comportamento dos consumidores atuais e a importância do atendimento humanizado neste contexto.
          </p>
        </CleverCard>
      </div>
    </div>

    <div id="image">
      <img
        class="ebook-description__ebook-cover-img"
        :src="isMobile ?
          require('@/assets/images/clever-ebook-mobile.png') :
          require('@/assets/images/clever-ebook-desktop.png')
        "
        alt="Capa Ebook Atendimento Humanizado"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EbookDescription',

  data () {
    return {
      isMobile: window.innerWidth <= 1280
    }
  }
}
</script>

<style lang="scss" scoped>
$wec-dark-blue: nth($colors-custom, 1);
$wec-green: nth($colors-custom, 2);
$mobile-max-width-breakpoint: map-get($breakpoints, 'lg');
$desktop-min-width-breakpoint: map-get($breakpoints, 'lg') + 1px;

@mixin desktop-spacing {
  margin-left: 10vw;
  max-width: 525px;
}

@mixin mobile-spacing {
  margin-left: 10vw;
  margin-right: 10vw;
}

.ebook-description {
  overflow: hidden;

  // @media: mobile
  @media only screen and (max-width: $mobile-max-width-breakpoint) {
    #title { grid-area: title; }
    #text { grid-area: text; }
    #image { grid-area: image; }

    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "image"
      "text";

    padding-top: 35px;
    padding-bottom: 40px;

    &__upper-title {
      @include mobile-spacing;
      font-size: 15px;
      line-height: 50px;
      font-weight: 500;
      color: $wec-dark-blue;
    }

    &__title {
      @include mobile-spacing;
      font-size: 17px;
      line-height: 27px;
      font-weight: 900;
      color: $wec-dark-blue;
    }

    &__subtitle-div {
      @include mobile-spacing;
      margin-top: 25px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 25px;
      font-weight: 600;
      color: $wec-dark-blue;
      background: linear-gradient(to top, $wec-green 50%, transparent 50%);
    }

    &__text {
      @include mobile-spacing;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: $wec-dark-blue;
      margin-top: 20px;
    }

    &__card-text {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: $wec-dark-blue;
      margin: 20px;
      transform: translate(-6px, -6px)!important;
    }

    &__clever-card {
      @include mobile-spacing;
      margin-top: 25px;
    }

    &__ebook-cover-img {
      max-width: 92vw;
      margin-top: 25px;
      margin-left: 10vw;
    }
  }

  // @media: desktop
  @media only screen and (min-width: $desktop-min-width-breakpoint) {
    #title { grid-area: title; }
    #text { grid-area: text; }
    #image { grid-area: image; }

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "title image"
      "text image";

    padding-top: 80px;
    padding-bottom: 80px;

    &__upper-title {
      @include desktop-spacing;
      font-size: 25px;
      line-height: 50px;
      font-weight: 500;
      color: $wec-dark-blue;
    }

    &__title {
      @include desktop-spacing;
      font-size: 30px;
      line-height: 40px;
      font-weight: 900;
      color: $wec-dark-blue;
    }

    &__subtitle-div {
      @include desktop-spacing;
      margin-top: 35px;
    }

    &__subtitle {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      color: $wec-dark-blue;
      background: linear-gradient(to top, $wec-green 50%, transparent 50%);
    }

    &__text {
      @include desktop-spacing;
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: $wec-dark-blue;
      margin-top: 28px;
    }

    &__card-text {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: $wec-dark-blue;
      margin: 25px;
      transform: translate(-6px, -6px)!important;
    }

    &__clever-card {
      @include desktop-spacing;
      margin-top: 30px;
    }

    &__ebook-cover-img {
      max-width: 100%;
      max-height: 768px;
    }
  }

}
</style>
