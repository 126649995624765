<template>
  <header>
    <div class="base-header__container">
      <a href="https://weclever.co/" target="_blank" class="base-header__anchor">
        <img
          :src="require('@/assets/images/weclever-logo.png')"
          alt="WeClever Logo"
          class="base-header__logo"
        >
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: 'BaseHeader'
}
</script>

<style lang="scss" scoped>
$wec-dark-blue: nth($colors-custom, 1);

.base-header {
  align-self: start;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 0 0 30px 30px;
  background-color: $wec-dark-blue;

  &__container {
    display: grid;
    height: 90px;
    column-gap: 30px;
    background: $wec-dark-blue;
  }

  &__anchor {
    align-self: center;
    justify-self: center;
    width: 101px;
    height: 41px;
  }

  &__logo {
    align-self: center;
    justify-self: center;
    width: 101px;
    height: 41px;
  }
}
</style>
