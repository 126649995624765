<template>
  <div class="clever-card">
    <div class="clever-card__front-card"></div>
    <slot class="clever-card__content"></slot>
  </div>
</template>

<script>
export default {
  name: 'CleverCard'
}
</script>

<style lang="scss" scoped>
$wec-dark-blue: nth($colors-custom, 1);
$wec-green: nth($colors-custom, 2);

.clever-card {
  // card shadow
  z-index: 0;
  position: relative;
  border: 1px solid #172542;
  border-radius: 25px;
  background-color: $wec-green;

  &__front-card {
    z-index: -1;
    left: 0px;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    border: 1px solid $wec-dark-blue;
    background-color: #fff;
    transform: translate(-6px, -6px);
  }

  &__content {
    z-index: 9000;
  }
}
</style>
